import React from "react"
import "../components/layout.css"
import './index.css';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Flex, Heading, Label, Text, Box, Image } from '@theme-ui/components'


const textCollection = [
   'Network access logs with IP addresses of the website visitors are stored for maintenance purposes. They are permanently removed after 30 days.',
   'Website uses cookies.',
];


const TermsPage = () => {
  return (
    <Layout >
      <SEO title="Terms and Privacy Policy" />
      <section>
            <Flex sx={{ justifyContent: 'center', width: '100vw', height: '100vh', padding: 1 }}>
                <Flex sx={{ width: '100%', flexDirection: 'column', px: 4, pt: 5, alignItems: 'center'}}>
                    <Heading py={2} as="h3" pb={4} sx={{textAlign: 'center'}}>Terms and Privacy Policy</Heading>
                    {
                        textCollection.map((text, index) => {
                            return (
                                // <p>test</p>
                                <Text key={index} sx={{textAlign: 'left', paddingX: ['1rem','6rem'], paddingY: '0.2rem', width: '100%'}}>
                                    { text }
                                </Text>
                            )
                        })
                    }
                </Flex>
            </Flex>
        </section>
    </Layout>
  )
}

export default TermsPage
